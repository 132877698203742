<template>
  <nav v-if="header !== null">
    <div class="flex justify-between">
      <h1>
        <router-link to="/">
          Strandbeest
        </router-link>
      </h1>
      <button 
        class="outline-none" 
        @click="expand = !expand"
      >
        <h1>{{ expand ? 'Close' : 'Menu' }}</h1>
      </button>
    </div>

    <transition-expand>
      <ul class="pb-2" v-if="expand">
        <template v-for="(item, index) in header.menu">
          <template v-if="item.__component == 'menu.link'">
            <router-link 
              tag="li" 
              @click.native="expand = false"
              @click="expand = false"
              class="text-base no-underline py-1" 
              :to="item.url" 
              :key="index"
            >
              {{ item.label }}
            </router-link>
          </template>
          <template v-if="item.__component == 'menu.dropdown'">
            <li 
              :key="index"
              class="text-base no-underline py-1"
            >
              <span @click="subMenu(index)" class="flex justify-between">
                {{ item.label }} 
                <img :class="[{ '-rotate-90': subMenuIndex !== index }, 'transform inline w-3']" src="@/assets/svg/arrow-black-down.svg"/>
              </span>
              <transition-expand>
                <ul v-show="subMenuIndex === index" class="pl-5">
                  <router-link 
                    tag="li" 
                    v-for="(item, key) in item.link" 
                    class="text-base no-underline py-1 last:pb-0" 
                    @click.native="expand = false" 
                    @click="expand = false"
                    :to="item.url" 
                    :key="key"
                  >
                    {{ item.label }}
                  </router-link>
                </ul>
              </transition-expand>
           </li>
          </template>
        </template>
      </ul>
    </transition-expand>
  </nav>
</template>

<script>
import TransitionExpand from '../transitions/TransitionExpand.vue'

export default {
  name: 'MobileMenu',
  props: ['header'],
  components: { 
    TransitionExpand 
  },
  data() {
    return {
      expand: false,
      subMenuIndex: null,
    }
  },
  methods: {
    subMenu(index) {
      (this.subMenuIndex === index) ? this.subMenuIndex = null : this.subMenuIndex = index
    }
  }
}
</script>